import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Container from './Container';
import { Card } from '../Card';
import { Props, Playground } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "container"
    }}>{`Container`}</h1>
    <p>{`Container is a `}<a parentName="p" {...{
        "href": "/components/Flex"
      }}>{`Flex`}</a>{` has a max-width and centers content on the page, it defaults to `}<inlineCode parentName="p">{`display: flex`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Container} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <p>{`Set `}<inlineCode parentName="p">{`width="narrow"`}</inlineCode>{` for to optimize body text width, set it to `}<inlineCode parentName="p">{`base`}</inlineCode>{` for a wider layout, `}<inlineCode parentName="p">{`full`}</inlineCode>{` to fill any available space.`}</p>
    <p>{`Only set `}<inlineCode parentName="p">{`maxWidth`}</inlineCode>{` if absolutely necessary and you cant use narrow/base widths.`}</p>
    <p>{`Container implements the styled-system props for:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://styled-system.com/api#space"
        }}>{`space`}</a>{` (only use full-length props. e.g. `}<inlineCode parentName="li">{`paddingBottom`}</inlineCode>{`, not `}<inlineCode parentName="li">{`pb`}</inlineCode>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://styled-system.com/api#layout"
        }}>{`layout`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://styled-system.com/api#color"
        }}>{`color`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://styled-system.com/api#border"
        }}>{`border`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://styled-system.com/api#flex"
        }}>{`flexbox`}</a></li>
    </ul>
    <Playground __position={1} __code={'<Container bg=\"neutral.light\" padding=\"large\">\n  <Card margin=\"base\">One</Card>\n  <Card margin=\"base\">Two</Card>\n</Container>'} __scope={{
      props,
      DefaultLayout,
      Container,
      Card,
      Props,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Container bg="neutral.light" padding="large" mdxType="Container">
    <Card margin="base" mdxType="Card">One</Card>
    <Card margin="base" mdxType="Card">Two</Card>
  </Container>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      